@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap);
*{margin:0;padding:0;box-sizing:border-box}html{scroll-behavior:smooth}body{font-family:'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#0f0e16;color:#fff}h1,h2,h3,h4{font-family:'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight:700}h1{font-size:5em}@media (max-width: 480px){h1{font-size:4em}}h2,h3{font-size:2.9em}@media (max-width: 480px){h2,h3{font-size:2.5em}}p{font-weight:200;font-size:1.2em;color:#dcdcdc;line-height:2.3}a{color:inherit;text-decoration:none}img{width:100%}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.container{max-width:1200px;margin:0 auto;padding:0 1rem}@media (max-width: 1200px){.container{padding:0 2rem}}@media (max-width: 480px){.container{padding:0 1rem}}.text-primary{color:#3fc8e1}ul{list-style:none}@media screen and (max-width: 1024px){body{font-size:0.8em}}

.Navbar-Container{display:flex;justify-content:center;align-items:center}.Navbar{display:flex;justify-content:center;align-items:center;list-style:none;font-size:1.2em;overflow:hidden;margin-right:2em}.Navbar li{cursor:pointer;margin:0 2em;transform:translate(0, 100%);opacity:0;transition:all 0.5s ease}.Navbar li:nth-child(1){transition-delay:0s}.Navbar li:nth-child(2){transition-delay:0.1s}.Navbar li:nth-child(3){transition-delay:0.2s}.Navbar li:nth-child(4){transition-delay:0.3s}.Navbar li:hover{color:#3fc8e1}.Navbar-Container.active .Navbar li{transform:translate(0, 0);opacity:1}.burger-menu{cursor:pointer;position:relative;width:27px;height:19px;transition:all 0.25s ease-in-out;transform:scaleX(-1);-webkit-filter:FlipH;filter:FlipH}.line{height:2px;width:100%;top:50%;transform:translateY(-50%)}.burger-menu:before,.burger-menu:after,.line{content:'';position:absolute;background-color:#fff;height:2px;width:100%;transition:all 0.25s ease-in-out;border-radius:5px}.burger-menu:before{bottom:0%;width:50%}.burger-menu:after{top:0;width:80%}.menu-active{transform:rotate(45deg)}.menu-active .line{opacity:0}.menu-active.burger-menu:before{transform:rotate(-90deg);bottom:43%;width:100%}.menu-active.burger-menu:after{top:50%;transform:translateY(-50%);width:100%}@media screen and (max-width: 768px){.Navbar{display:block;position:fixed;top:0;left:0;z-index:20;padding:5em;transition:all 0.2s ease}.Navbar li{margin:0 0 2em 0}.Navbar-Container.Navbar-Container.active .Navbar{height:100vh;background:rgba(15,14,22,0.8)}}

.Header-Container{padding:50px 0;position:-webkit-sticky;position:sticky;top:0;transition:all 0.3s ease-in-out;z-index:10}.Header-Container .container{display:flex;justify-content:space-between;align-items:center}.Header-Container.fixed{background-color:rgba(15,14,22,0.969);border-bottom:1px solid rgba(112,112,112,0.267)}

.Social-Icons{display:flex;justify-content:center;align-items:center}.Social-Icons a{margin-right:3em}.Social-Icons .Social-Icon{width:25px;height:auto;fill:#bfbfc0}

.Footer-Container{border-top:1px solid rgba(112,112,112,0.267);padding:1.5em 0}.Footer-Container .container{display:flex;justify-content:space-between;align-items:center}.Footer-Container p{font-size:0.9em}@media (max-width: 480px){.Footer-Container .container{display:block;text-align:center}.Footer-Container .Social-Icons{margin-bottom:2em}.Footer-Container .Social-Icons a{margin:0 1.5em}.Footer-Container .Social-Icons .Social-Icon{width:18px;height:auto;fill:#bfbfc0}}

.Hero-Container{margin:0 2em;min-height:calc(100vh - 250px);display:flex;flex-direction:column;justify-content:center;text-align:center;width:720px;margin:auto}.Hero-Title{line-height:1.3;margin-bottom:50px}.Hero-Title .emoji{display:inline-block;margin-left:10px;transform-origin:bottom center;-webkit-animation:balanceo 0.9s ease-in-out infinite alternate;animation:balanceo 0.9s ease-in-out infinite alternate}@-webkit-keyframes balanceo{0%{transform:rotate(-10deg)}100%{transform:rotate(15deg)}}@keyframes balanceo{0%{transform:rotate(-10deg)}100%{transform:rotate(15deg)}}.Hero-Container .Social-Icons{position:absolute;flex-direction:column;left:5%;bottom:12%}.Hero-Container .Social-Icons a{margin-right:0;margin-bottom:2em}.Hero-Paragraph{width:95%}@media screen and (max-width: 1024px) and (min-width: 768px){.Hero-Container{width:80%}}@media screen and (max-width: 768px){.Hero-Container{width:90%}.Hero-Container .Social-Icons{position:static;flex-direction:row;margin-top:3em}.Hero-Container .Social-Icons a{margin-right:3em;margin-bottom:0}}

.Button{text-transform:uppercase;display:flex;justify-content:center;align-items:center;position:relative;padding:14px 12px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:inline-block}.Button:before{content:'';position:absolute;width:22%;height:100%;top:0;left:0;background-color:#3fc8e1;z-index:-1;opacity:0.15;border-radius:4px;-webkit-animation:reduce 0.5s ease-in-out forwards;animation:reduce 0.5s ease-in-out forwards}.Button:hover:before{-webkit-animation:expand 0.5s ease-in-out forwards;animation:expand 0.5s ease-in-out forwards}.Button:hover img{-webkit-animation:twinkle 0.4s ease-in-out infinite alternate;animation:twinkle 0.4s ease-in-out infinite alternate}.Button:hover img:nth-child(2){-webkit-animation-delay:0.1s;animation-delay:0.1s}.Button img{width:7px}.Button img:nth-child(1){margin-left:0.5em;opacity:0.3}@-webkit-keyframes expand{0%{width:20%}60%{width:108%}100%{width:100%}}@keyframes expand{0%{width:20%}60%{width:108%}100%{width:100%}}@-webkit-keyframes reduce{0%{width:100%}60%{width:15%}100%{width:20%}}@keyframes reduce{0%{width:100%}60%{width:15%}100%{width:20%}}@-webkit-keyframes twinkle{0%{opacity:1}100%{opacity:0.2}}@keyframes twinkle{0%{opacity:1}100%{opacity:0.2}}

.Project-Container{justify-self:center;margin:0em 0}.Project-Container:nth-child(odd){flex-direction:row-reverse}.Project-Img,.Project-Info{margin:0 auto}.Project-Img{margin-bottom:2em}.Img-Box{display:inline-block;width:200px;height:300px;overflow:hidden;border-radius:3px;margin:0 0.5em;transform:translateY(-15px)}.Img-Box:nth-child(2){transform:translateY(15px)}.Img-Box img{height:100%;object-fit:cover}.Project-Info .Project-Title{text-transform:uppercase;margin-bottom:-0.2em;font-size:2em}.Project-Info h3{margin-bottom:0.8em;font-size:2em}.Project-Info .Project-Specs{font-size:1.2em;margin-bottom:1.5em}.Project-Info p{margin-bottom:2em}.Project-Button{margin-right:2em;margin-bottom:1em;display:inline-block}@media screen and (max-width: 1024px) and (min-width: 768px){.Img-Box{width:150px;height:250px}}@media screen and (max-width: 768px){.Project-Container{display:block;margin:15em 0}.Project-Img,.Project-Info{width:100%}.Project-Img{display:block;text-align:center;margin-bottom:2em}.Project-Img .Img-Box{width:150px;height:250px;transform:translate(0)}}@media screen and (max-width: 768px){.Project-Container{display:block;margin:15em 0}.Project-Info .Project-Specs{font-size:1.3em}.Project-Img .Img-Box{width:120px;height:220px}.Project-Button{margin-right:2em}}

.Projects-Container{margin:8rem 0}.Projects-Title{text-transform:uppercase;margin-bottom:5em}@media screen and (min-width: 1024px){.Projects-Grid{display:grid;grid-template-columns:repeat(3, 1fr);justify-content:space-around;grid-gap:13em 10em;gap:13em 10em}}

.Contact-Container{text-align:center;margin-bottom:10em}.Contact-Container h2{margin-bottom:1em}.Contact-Container p{margin:auto;margin-bottom:3em;width:78%}@media screen and (max-width: 768px){.Contact-Container p{width:100%}}

.About-Container .container{display:flex;justify-content:center;margin:9em auto}.About-Img,.About-Text{width:50%}.About-Img{display:flex;justify-content:center;position:-webkit-sticky;position:sticky;align-self:flex-start;top:200px;height:calc(100vh - 250px)}.col:nth-child(1) .About-Img-Box:nth-child(1){-webkit-animation-delay:0s;animation-delay:0s}.col:nth-child(1) .About-Img-Box:nth-child(2){-webkit-animation-delay:0.1s;animation-delay:0.1s}.col:nth-child(2) .About-Img-Box:nth-child(1){-webkit-animation-delay:0.2s;animation-delay:0.2s}.col:nth-child(2) .About-Img-Box:nth-child(2){-webkit-animation-delay:0.3s;animation-delay:0.3s}.About-Img-Box{display:block;width:140px;height:250px;overflow:hidden;border-radius:3px;margin:14px 7px;-webkit-animation:moveFloat 1.5s ease infinite alternate;animation:moveFloat 1.5s ease infinite alternate}.About-Img-Box img{height:100%;object-fit:cover}.About-Text{padding-left:1em}.About-Text .About-Title{margin-bottom:2em;position:relative}.About-Text .About-Title .Exclamation{font-size:4em;font-family:'Poppins', sans-serif;position:absolute;top:-12px;left:270px;z-index:-1}.About-Text .About-Title .Exclamation span{opacity:0.3}.About-Text p{margin-bottom:2em}@-webkit-keyframes moveFloat{0%{transform:translateY(-20px);transform:scale(1)}100%{transform:translateY(20px);transform:scale(1.05)}}@keyframes moveFloat{0%{transform:translateY(-20px);transform:scale(1)}100%{transform:translateY(20px);transform:scale(1.05)}}@media screen and (max-width: 768px){.About-Img{position:absolute;opacity:0.3;width:100%}.About-Text{width:100%}}

.NotFound-Container{text-align:center;display:flex;flex-direction:column;justify-content:center;align-items:center;height:calc(100vh - 130px - 82px)}.NotFound-Container h1{font-size:8em}.NotFound-Container p{font-size:2em;line-height:1.5em;margin-bottom:1.5em}

