@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body;
  color: $light;
}

h1,
h2,
h3,
h4 {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: $primary-title;
  @media (max-width: 480px) {
    font-size: 4em;
  }
}

h2,
h3 {
  font-size: $secondary-title;

  @media (max-width: 480px) {
    font-size: 2.5em;
  }
}

p {
  font-weight: 200;
  font-size: $font-size;
  color: $text-color;
  line-height: 2.3;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 1200px) {
    padding: 0 2rem;
  }

  @media (max-width: 480px) {
    padding: 0 1rem;
  }
}

.text-primary {
  color: $primary;
}

ul {
  list-style: none;
}

@import './global-media';
