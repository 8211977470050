.NotFound-Container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 130px - 82px);

  h1 {
    font-size: 8em;
  }

  p {
    font-size: 2em;
    line-height: 1.5em;
    margin-bottom: 1.5em;
  }
}
